import clein11 from '../../assests/C-Logo11.png'
import clein12 from '../../assests/C-Logo12.png'
import clein13 from '../../assests/C-Logo13.png'
import clein14 from '../../assests/C-Logo14.png'
import clein15 from '../../assests/C-Logo15.png'
import clein16 from '../../assests/C-Logo16.png'
import clein17 from '../../assests/C-Logo17.png'
import clein18 from '../../assests/C-Logo18.png'
import clein19 from '../../assests/C-Logo19.png'
const client2 = [
    {
        id: 1,
        clientLogo2: clein11,
    },
    {
        id: 1,
        clientLogo2: clein12,
    },
    {
        id: 1,
        clientLogo2: clein13,
    },
    {
        id: 1,
        clientLogo2: clein14,
    },
    {
        id: 1,
        clientLogo2: clein15,
    },
    {
        id: 1,
        clientLogo2: clein16,
    },
    {
        id: 1,
        clientLogo2: clein17,
    },
    {
        id: 1,
        clientLogo2: clein18,
    },
    {
        id: 1,
        clientLogo2: clein19,
    },
    
]
export default client2;