import clein1 from '../../assests/C-Logo1.png'
import clein2 from '../../assests/C-Logo2.png'
import clein3 from '../../assests/C-Logo3.png'
import clein4 from '../../assests/C-Logo4.png'
import clein5 from '../../assests/C-Logo5.png'
import clein6 from '../../assests/C-Logo6.png'
import clein7 from '../../assests/C-Logo7.png'
import clein8 from '../../assests/C-Logo8.png'
import clein9 from '../../assests/C-Logo9.png'
import clein10 from '../../assests/C-Logo10.png'
const client1 = [
    {
        id: 1,
        clientLogo1: clein1,
    },
    {
        id: 1,
        clientLogo1: clein2,
    },
    {
        id: 1,
        clientLogo1: clein3,
    },
    {
        id: 1,
        clientLogo1: clein4,
    },
    {
        id: 1,
        clientLogo1: clein5,
    },
    {
        id: 1,
        clientLogo1: clein6,
    },
    {
        id: 1,
        clientLogo1: clein7,
    },
    {
        id: 1,
        clientLogo1: clein8,
    },
    {
        id: 1,
        clientLogo1: clein9,
    },
    {
        id: 1,
        clientLogo1: clein10,
    },
    
]
export default client1;