import person1 from '../../assests/Outdoors-man-portrait_(cropped).jpg'
const Testimonial = [
{
    person:person1,
    review:"Video lectures form the crux of IIDE's student & corporate training curriculums. So creating content matching global education standards is paramount. TRC promptly adapted to brand guidelines & delivered quality animation that brought our expert's explanation to life.",
     name:"Bhumit Gor",
     designation:"Co-Founder & Guitarist, Last Minute Band",
},
{
    person:person1,
    review:"Video lectures form the crux of IIDE's student & corporate training curriculums. So creating content matching global education standards is paramount. TRC promptly adapted to brand guidelines & delivered quality animation that brought our expert's explanation to life.",
     name:"Bhumit Gor",
     designation:"Co-Founder & Guitarist, Last Minute Band",
},
{
    person:person1,
    review:"Video lectures form the crux of IIDE's student & corporate training curriculums. So creating content matching global education standards is paramount. TRC promptly adapted to brand guidelines & delivered quality animation that brought our expert's explanation to life.",
     name:"Bhumit Gor",
     designation:"Co-Founder & Guitarist, Last Minute Band",
},
{
    person:person1,
    review:"Video lectures form the crux of IIDE's student & corporate training curriculums. So creating content matching global education standards is paramount. TRC promptly adapted to brand guidelines & delivered quality animation that brought our expert's explanation to life.",
     name:"Bhumit Gor",
     designation:"Co-Founder & Guitarist, Last Minute Band",
},
]
export default Testimonial;